<!--
 * @Author: wjc
 * @Date: 2023-11-10 16:01:22
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-05-06 09:07:25
 * @Description: 
-->
<template>
  <div class="footer relative bg-bg-4 text-color-whiter font-s-1.4rem py-20px lt-xs:font-s-1.6rem">
    <MRow
      align="middle"
      justify="space-between"
      :span="span"
      :gutter="$screen.higherThan('xs') ? 22 : null"
    >
      <el-col :md="18" :xs="24">
        <el-space
          size="large"
          class="links pb-20px mb-20px"
          :alignment="$screen.higherThan('sm') ? 'flex-start' : 'center'"
          :direction="$screen.higherThan('sm') ? 'vertical' : 'horizontal'"
        >
          <div class="w-full font-s-1.6rem text-left">友情链接</div>
          <el-select placeholder="请选择" class="link-select">
            <el-option value="1">
              <MLink to="https://zjt.hainan.gov.cn" target="_blank">海南省住房和城乡建设厅</MLink>
            </el-option>
            <el-option value="2">
              <MLink :to="`${config.footerWrite.adminUrl}`" target="_blank">
                海南省物业服务企业信用登记评价系统
              </MLink>
            </el-option>
          </el-select>
        </el-space>
        <div class="flex justify-evenly font-s-1.4rem lt-xs:font-s-1.6rem">
          <el-row class="flex-1">
            <el-col
              :md="5"
              :sm="24"
              :xs="24"
              class="flex! items-center border-r-1px border-r-dashed border-r-white/10 pr-8px md:mr-24px lt-xs:border-r-none"
            >
              <div class="wh-40px mr-8px">
                <img src="/images/holder-logo.png" />
              </div>
              <div>
                <div class="font-s-1.4rem lt-xs:font-s-1.6rem font-400 mb-16px">主办单位</div>
                <div class="font-s-1.6rem lt-xs:font-s-1.6rem font-600">海南省住建厅</div>
              </div>
            </el-col>
            <el-col :md="6" :sm="24" :xs="24" class="md:my-0px lt-xs:my-24px">
              <div class="md:mb-16px lt-xs:mb-8px">
                承办单位：{{ config.footerWrite.organizer }}
              </div>
              <div>支持单位：{{ config.footerWrite.organizer }}</div>
            </el-col>
            <el-col :md="12" :sm="24" :xs="24" class="lt-xs:my-8px">
              <div class="mb-16px">联系电话：0898-65342345</div>
              <div
                class="flex items-center justify-start lt-xs:flex-wrap lt-xs:flex-col lt-xs:items-start"
              >
                <div class="lt-xs:mb-12px">上班时间：</div>
                <el-space
                  size="large"
                  :spacer="$screen.higherThan('sm') ? '' : '|'"
                  :direction="$screen.higherThan('sm') ? 'vertical' : 'horizontal'"
                >
                  <span>上午 08:30 - 12:30</span>
                  <span>下午 14:30 - 17:30</span>
                </el-space>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :md="6" :xs="24" class="wc-qrcode">
        <!-- <el-space> -->
        <div class="text-center bg-#2B3954 p-8px font-s-14px">
          <img src="/images/qrcode.png" class="wh-85px" />
          <div class="mt-4px text-overflow">
            <div>物业协会</div>
            <div>微信公众号</div>
          </div>
        </div>
        <div class="text-center bg-#2B3954 p-8px font-s-14px">
          <img src="/images/qrcode.png" class="wh-85px" />
          <div class="mt-4px text-overflow">
            <div>海南省住建厅</div>
            <div>微信公众号</div>
          </div>
        </div>
        <!-- </el-space> -->
      </el-col>
    </MRow>
    <div class="border-t-1px border-t-solid border-t-white/10 mt-20px">
      <MRow :span="span" :gutter="$screen.higherThan('xs') ? 20 : null">
        <el-col :xs="24" :md="16" class="font-s-1.4rem text-color-3 pt-20px lt-xs:font-s-1.6rem">
          <el-row>
            <el-col :md="4" :xs="24">© 2000-{{ new Date().getFullYear() }}</el-col>
            <el-col :md="8" :xs="24">{{ config.footerWrite.url }}</el-col>
            <el-col :md="8" :xs="24">{{ appName }}</el-col>
          </el-row>
        </el-col>
        <el-col
          :xs="24"
          :md="8"
          class="font-s-1.4rem text-color-3 pt-20px lt-xs:font-s-1.6rem lt-xs:text-left md:text-right"
        >
          <MLink to="https://beian.miit.gov.cn" target="_blank">琼ICP备2023015601号-1</MLink>
        </el-col>
      </MRow>
    </div>
  </div>
</template>

<script setup lang="ts">
  import config, { appName } from '~/constants'
  defineOptions({
    name: 'Footer',
  })

  const $screen = useScreen()
  const span = computed(() => $screen.span.value)
</script>

<style scoped lang="scss">
  .links {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .wc-qrcode {
    @apply md:justify-end;
    display: grid;
    grid-template-columns: repeat(2, 98px);
    gap: 24px;
  }
</style>
